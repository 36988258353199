.HomeHighlightCards {
  display: flex;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: space-around;
}

.highLightCard {
  margin: 1em !important;
  height: 12em !important;
  width: 19em !important;
}
