.mapContainer {
  width: 97%;
  margin: 0 auto;
}
.header {
  text-align: center;
  margin: 2em;
}
.header h4 {
  font-size: 2.5em;
  font-weight: 500;
  margin: 0.4em;
}
.header p {
  font-size: 1.15em;
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
}
.imageContainer img {
  width: 100%;
  transform: scaleX(-1);
  align-items: end;
}
.mapContainer iframe {
  width: 100%;
  height: 30em;
  border-radius: 1em;
}

@media screen and (max-width: 48em) {
  .mapContainer iframe {
    height: 20em;
  }
}
