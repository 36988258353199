.panel,
.panel1 {
  margin: 8em 1em 1em;
}
.panel1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
.panel1 img {
  width: 80%;
}
.panel1 div {
  width: 30em;
  text-align: center;
}
.PanelContent {
  text-align: center;
  padding: 3em;
}
.PanelHeading {
  font-size: 2.5em;
  font-weight: 500;
  margin: 0.4em;
}
.PanelCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card {
  margin: 1em;
  width: 18em;
  height: auto;
}
.card div p {
  font-size: 1em;
}
.card div h4 {
  font-size: 1.15em;
}
.card img,
.card div div {
  width: 2.5em;
  height: 2.5em !important;
}
@media screen and (max-width: 62em) {
  .panel {
    margin-top: 2em;
  }
}
