.HighlightCard {
  width: 22em;
  height: 25em;
  border: 0.01em solid #d8dff2;
}
.isLink {
  cursor: pointer;
  transition: scale 0.2s ease-in-out;
}
.isLink:hover {
  scale: 1.01;
}
.cardContent {
  padding: 1em;
}
.cardTitle {
  font-size: 1.3em;
  font-weight: 500;
}
.cardDescription {
  font-size: 1.1em;
  font-weight: 300;
}
.cardImage,
.cardImage img {
  height: 10.875em;
}
