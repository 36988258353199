.HeroSection {
  display: flex;
  gap: 1rem;
}
.inverse {
  flex-direction: row-reverse;
}
.contentSection,
.imageSection {
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageSection img {
  width: 90%;
}

@media screen and (max-width: 62em) {
  .HeroSection {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .contentSection {
    min-width: fit-content;
    justify-content: center;
  }
  .imageSection {
    display: block;
    min-width: fit-content;
    text-align: center;
  }
}
@media screen and (max-width: 35em) {
  .imageSection img {
    width: 95%;
  }
}
