.HomeServicesSection {
  padding-top: 6em;
}
.header {
  text-align: center;
  margin: 2em;
}
.header h4 {
  font-size: 2.5em;
  font-weight: 500;
  margin: 0.4em;
}
.header p {
  font-size: 1.15em;
  font-weight: 400;
  width: 80%;
  margin: 0 auto;
}
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.imageCards {
  margin: 1em;
}
.imageCards img {
  opacity: 0.6;
}
.imageCards a {
  text-decoration: none;
}
.imageCards p {
  height: 2em;
}
