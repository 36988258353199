.HomePage {
}
.upIcon {
  height: 5em;
  width: 5em;
  z-index: 55;
  background-color: white;
  border-radius: 50%;
}
.panelWrapper{
  background-image: url('./bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.bannerWrapper{
  background-image: url('./bg1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.directionWrapper {
  background-image: url('./bg2.png');
  background-repeat: no-repeat;
  background-size: cover;
}