.TestimonialCard {
  width: 24em;
  height: 28em;
}
.cardContent {
  padding: 1.75em;
}
.divider {
  opacity: 0.7;
width: 100%;
  display: block;
  height: 1px;
  margin: 2em 0;
}
.comment {
  font-size: 1.15em;
  height: 13em;
  font-weight: 300;
  overflow: hidden;
}
.imageContainer img {
  width: 2.9em;
  height: 2.9em;
  border-radius: 50%;
  object-fit: cover;
}
.profile {
  display: flex;
  align-items: center;
  gap: 1em;
}
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3.15em;
  height: 3.15em;
  border: 0.1em solid;
}
.profileDetails p {
  margin: 0;
  font-weight: 400;
}
.profileDetails p:nth-child(2) {
  opacity: 0.9;
}
