.inputContainer input,
.inputTextArea > textarea {
  padding: 0.118em 0.1em 1em;
  width: 100%;
  font-size: 1em;
  border: none;
  background-color: white;
}

.inputTextArea textarea {
  resize: none;
}

.inputContainer input:focus,
.inputTextArea textarea:focus {
  outline: none;
}

.inputContainer span,
.inputTextArea span {
  font-size: 1em;
  opacity: 0.3;
}
.inputContainer,
.inputTextArea {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 1em 0.32em;
  border: 0.0625em solid;
  border-radius: 1em;
  min-width: 12em;
  width: 13em;
  text-align: center;
  background-color: white;
}
.inputTextArea {
  width: 100%;
}
.inputTextArea button {
  position: relative;
  left: 85%;
  border: none;
  padding: 0.75em;
  font-size: 1em;
  border-radius: 1em;
  cursor: pointer;
}
.formInputs {
  display: flex;
  gap: 1em;
  margin: 0 auto;
  padding: 1em 0em;
  flex-wrap: wrap;
  justify-content: center;
}
.emailInput div {
  width: 100%;
}
@media screen and (max-width: 83.5em) {
  .inputContainer {
    width: 100%;
  }
}
@media screen and (max-width: 37.2em) {
  .inputTextArea button {
    left: 80%;
  }
}

@media screen and (max-width: 30em) {
  .inputTextArea button {
    left: 72%;
  }
}
