.TestimonialCarouselContainer {
  max-width: 80vw;
  margin: 0 auto;
  padding: 3em 2em;
}
.cardBottom {
  color: black;
}
.TestimonialCarousel {
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
}
.TestimonialContainer {
  display: flex;
  gap: 2em;
  min-width: 100%;
}
.TestimonialCarousel p {
  line-height: 1.4em;
}
.TestimonialCarousel button,
.TestimonialCarouselMobile button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.TestimonialCarousel button:disabled,
.TestimonialCarouselMobile button:disabled {
  cursor: default;
}
.TestimonialCarouselMobile {
  padding-top: 1em;
  display: none;
  text-align: center;
}
.businessImage {
  padding: 0.6em;
}
.TestimonialComment {
  margin-top: 0.4em;
  overflow-y: auto;
  height: 10em;
}
.CarouselHeading {
  font-size: 2.5em;
  font-weight: 500;
}
.laptopCarousel,
.tabletCarousel {
  display: none;
}

@media screen and (max-width: 68em) {
  .desktopCarousel {
    display: none;
  }
  .laptopCarousel {
    display: block;
  }
  .TestimonialCarouselContainer {
    max-width: 75vw;
  }
}
@media screen and (max-width: 50em) {
  .laptopCarousel {
    display: none;
  }
  .tabletCarousel {
    display: block;
  }
  .TestimonialCarousel {
    gap: 0;
  }
  .TestimonialContainer {
    justify-content: center;
    gap: 0;
  }
}
@media screen and (max-width: 35em) {
  .TestimonialCarousel button {
    display: none;
  }
  .TestimonialCarouselMobile {
    display: block;
  }
}
