.bannerWrapper{
  background-image: url('./bg1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.BannerImage {
  width: 100% !important;
}
.header {
  text-align: center;
  margin: 2em;
  margin-top: 4em;
  padding-top: 2em;
}
.header h4 {
  font-size: 2.5em;
  font-weight: 500;
  margin: 0.4em;
}
.header p {
  font-size: 1.15em;
  font-weight: 400;
  width: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 62em) {
  .BannerImage {
    display: none;
  }
  .header p{
    width: 80%;
  }
  .header h4{
    font-size: 2.25em;
  }
}
