.ImageLinkCard,
.ImageLinkCard img {
  background-color: black;
  height: 26em;
  width: 23em;
  border-radius: 1em;
}
.ImageLinkCard img {
  width: 100%;
  object-fit: cover;
  opacity: 0.75;
}
.title {
  width: 35%;
  position: relative;
  color: white;
  left: 7%;
  bottom: 98%;
}
.title p {
  font-size: 1.75em;
  line-height: 1.25em;
  font-weight: 500;
}
@media screen and (max-width: 32em) {
  .title {
    bottom: 95%;
    left: 0;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ImageLinkCard,
  .ImageLinkCard img {
    height: 16em;
    margin: 0 auto;
    width: 95vw;
  }
}
