.MobileNavButton {
  z-index: 2;
  position: absolute;
  right: 2em;
  top: 3.25em;
  cursor: pointer;
  display: none;
  transition: rotate 0.25s;
}
.open {
  rotate: -90deg;
}
.MobileNavButton > div {
  height: 0.25em;
  width: 1.75em;
  background-color: black;
  border-radius: 1em;
  margin: 0.3em;
}

@media screen and (max-width: 62em) {
  .MobileNavButton {
    display: block;
  }
}
